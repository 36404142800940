import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import MainSection from "../objects/MainSection"
import Header from "../components/Header"
import Footer from "../components/Footer"
import TitleSection from "../objects/TitleSection" 
import TextSection from "../objects/TextSection" 

const Container = styled.section`
   height: 70vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 0;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header title="Promoção de inverno ativado - economize até 25%" />
    <MainSection>
       <Container>
          <TitleSection titlepage="Erro 404" />
          <TextSection>
             Ops!! Página não encontrada... que tristeza!
          </TextSection>
       </Container>
    </MainSection>
    <Footer />
  </Layout>
)

export default NotFoundPage
